<template>
	<div>
		<a-crm-container>
			<template>
				<div class='a-crm-content'>
					<div class="a-crm-my-order-preloader" v-if='initLoading'>
						<div>
							<div class='a-crm-my-order-preloader-icon'>
								<span v-html="require(`!html-loader!@/assets/baggage.svg`)"/>
							</div>
						</div>
					</div>
					<div class='a-crm-content-box a-crm-my-order-content-box' v-if="order">
						<div class='a-crm-my-order-header'>
							<p class='a-crm-my-order-header-title'>{{ $t("crm.order") }} № {{ order.crmOrderId }}
								{{ $t("account.from") }} {{ $moment(order.dateFrom).format('DD.MM.YYYY') }}</p>
						</div>

						<div class='a-crm-info-status desktop-show' :style="'background-color: ' + order.crmStatus.color">
                            <p class='a-crm-info-status-title'>{{ order.crmStatus.name }}</p>
                        </div>

						<a-timeline :order='order' result hideImage v-if='order.items && order.items.length'/>
						<a-crm-tourists :tourists="order.paxes"/>
						<a-crm-policy :policy="order.cancellationPolicies"/>

						<div class='desktop-show'>
							<a-crm-order-info :order='order' @getData="getData"/>
						</div>
					</div>
				</div>

				<div class='desktop-hide' v-if="order">
					<a-crm-order-info :order='order' @getData="getData"/>
				</div>

			</template>
		</a-crm-container>
	</div>
</template>

<script>
import ACrmContainer from '@/views/crm/container';
import ACrmOrderInfo from '@/views/crm/components/order-info';
import ACrmTourists from '@/views/crm/components/tourists';
import ACrmPolicy from '@/views/crm/components/policy';
import ATimeline from '@/views/account/itinerary/timeline';
import {cabinet} from '@/services/request';

export default {
	components: {
		ACrmContainer,
		ACrmOrderInfo,
		ACrmTourists,
		ACrmPolicy,
		ATimeline
	},
	data: () => ({
		details: false,
		order: null,
		initLoading: true
	}),
	created() {
		this.getData();
	},
	methods: {
		getData() {
			cabinet.order.get(this.$route.params.id).then(r => {
				this.order = r.data;
			}).finally(() => this.initLoading = false)
		}
	}
}
</script>

<style lang="scss">
@import "@/styles/global/variables";
@import "@/styles/global/mixins";

.a-crm-my-order {
	&-content-box {
		box-shadow: none;
		padding-top: 0;
	}

	&-header {
		padding: 15px;
		background-color: rgba($cyprus, .03);
		border-radius: $border-radius;
		text-align: center;

		&-title {
			font-size: 16px;
			font-weight: 700;
			@include minw($grid-breakpoints-xs) {
				font-size: 20px;
			}
		}
	}

	&-preloader {
		padding: 50px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		@include minw($grid-breakpoints-xs) {
			height: calc(100% - 50px * 2);
		}

		&-icon {
			width: 125px;
			height: 132px;
			margin: auto;
			margin-bottom: 30px;
			@include minw($grid-breakpoints-xs) {
				width: 201px;
				height: 213px;
				margin-bottom: 40px;
			}
		}

		&-title {
			font-size: 25px;
			font-weight: 700;
			margin-bottom: 16px;
			@include minw($grid-breakpoints-xs) {
				font-size: 20px;
			}
		}

		&-text {
			font-size: 14px;
			font-weight: 400;
			@include minw($grid-breakpoints-xs) {
				font-size: 16px;
			}
		}
	}
}
</style>
