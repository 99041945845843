<template>
	<div class='a-crm-info'>
		<div class='a-crm-info-wrap'>
			<div class='a-crm-info-total'>
				<div class="row align-items-center">
					<div class="col">
						<p class='a-crm-info-total-text'>{{ $t('account.trips.total') }}:</p>
					</div>
					<div class="col-auto">
						<p class='a-crm-info-total-price'>
							{{ $helpers.amount(order.price.total, order.price.currency) }}</p>
					</div>
				</div>
			</div>

			<div class='a-crm-info-content'>
                <span class='app-link green--text a-crm-info-content-link'
											@click='details = !details'>{{ $t("account.buttons.commission") }} ({{
										details ? $t("account.buttons.hide") : $t("account.buttons.details")
									}})</span>
				<div class='a-crm-info-content-list' v-if='details'>
					<div class="row a-crm-info-content-item">
						<div class="col">
							<p class='a-crm-info-content-title'>{{ $t("account.itinerary.amount_paid") }}:</p>
						</div>
						<div class="col-auto">
							<p class='a-crm-info-content-price'>
								{{ $helpers.amount(order.price.payment, order.price.currency) }}</p>
						</div>
					</div>
					<div class="row a-crm-info-content-item">
						<div class="col">
							<p class='a-crm-info-content-title'>{{ $t("crm.amount_margin") }}:</p>
						</div>
						<div class="col-auto">
							<p class='a-crm-info-content-price'>
								{{ $helpers.amount(order.price.margin, order.price.currency) }}</p>
						</div>
					</div>
					<div class="row a-crm-info-content-item">
						<div class="col">
							<p class='a-crm-info-content-title'>{{ $t("crm.amount_bonuses") }}:</p>
						</div>
						<div class="col-auto">
							<p class='a-crm-info-content-price'>{{ $helpers.amount(0, order.price.currency) }}</p>
						</div>
					</div>
					<div class="row a-crm-info-content-item">
						<div class="col">
							<p class='a-crm-info-content-title'>{{ $t("account.itinerary.markup") }}:</p>
						</div>
						<div class="col-auto">
							<p class='a-crm-info-content-price'>
								{{ $helpers.amount(order.price.margin, order.price.currency) }}</p>
						</div>
					</div>
				</div>
			</div>

			<div class='a-crm-info-status' :style="'background-color: ' + order.crmStatus.color">
				<p class='a-crm-info-status-title'>{{ order.crmStatus.name }}</p>
			</div>
			<div class='a-crm-info-timer' v-if="showTimer">
				<p class='a-crm-info-timer-title'>{{ $t('account.awaiting_payment') }} {{timer.minutes}}:{{ timer.seconds }}</p>
			</div>
			<div class='a-crm-info-action-buttons'>
				<div class="a-crm-info-action-buttons-pay-process" v-if="paymentList.length">
					<p class="a-crm-info-action-buttons-pay-process-title">{{ $t('account.pay_process') }}</p>
					<p class="a-crm-info-action-buttons-pay-process-pay" v-for="(payment, key) in paymentList"
						 :key="key">{{ payment.date }} - {{ payment.amount }} $</p>
				</div>
				<p class='a-crm-info-pay-info'>{{ $t("account.pay_info") }}</p>
				<v-button block
									xxsmall
									color="green-persian white--text"
									:loading='loadingInvoice'
									@click='getInvoiceOrder'
									v-if="showInvoiceBtn">
					{{ $t("account.buttons.invoice") }}
				</v-button>
				<a :href="order.liqPay.url"
					 class="app-btn app-btn--block app-btn--xxsmall green-persian white--text"
					 target="_blank"
					 v-if="showPayBtn">
					{{ $t("account.buttons.pay") }}</a>

				<template v-for="(document, key) in documents">
					<a :key="key"
						 :href="document.link"
						 class="app-btn app-btn--block app-btn--xxsmall green-persian white--text"
						 target="_blank"
						 v-if="showDocumentBtn">
						{{ document.name }}
					</a>
				</template>

				<v-button block
									xxsmall
									color="red white--text"
									:loading='loadingCancel'
									@click='cancelOrder'
									v-if="showCancelBtn">
					{{ $t("account.buttons.cancel") }}
				</v-button>
				<v-button block
									xxsmall
									color="red white--text"
									:loading='loadingRefund'
									@click='refundOrder'
									v-if="showRefundBtn">
					{{ $t("account.buttons.refund") }}
				</v-button>
			</div>

		</div>
		<a-dialog-delete
			v-model='dialog.show'
			:title="dialog.title"
			:cancelButton="dialog.cancelButton"
			:nameButton="dialog.nameButton"
			@close-dialog="dialog.show = false"
			@action-dialog='dialog.action'
		/>
		<dialog-invoice
			v-model='invoiceDialog.show'
			:data="invoiceDialog.data"
			:crmOrderId="order.crmOrderId"
			@close-dialog="invoiceDialog.show = false"
		/>
	</div>
</template>

<script>
import {cabinet} from '@/services/request';
import ADialogDelete from '@/components/account/dialogs/delete';
import {i18n} from '@/plugins/i18n'
import dialogInvoice from '@/views/crm/components/dialog-invoice';

export default {
	components: {
		ADialogDelete,
		dialogInvoice
	},
	props: ['order'],
	data: () => ({
		isOpenDialog: false,
		paymentList: [],
		documents: [],
		invoiceDialog: {
			show: false,
			data: [],
		},
		dialog: {
			show: false,
			title: null,
			cancelButton: i18n.t("account.dialogs.buttons.no"),
			nameButton: i18n.t("account.dialogs.buttons.yes"),
			action: () => {
			},
		},
		details: false,
		loadingInvoice: false,
		loadingPay: false,
		loadingCancel: false,
		loadingRefund: false,

		deadline: 'Oct 5, 2023 20:45:00',

		timer:{
			minutes: '00',
			seconds: '00',
			expired: false
		}
	}),
	computed: {
		showTimer() {
			return this.showPayBtn;
		},
		showInvoiceBtn() {
			return ['paid_in_part', 'tour_confirmed', 'waiting_for_payment', 'awaiting_payment', 'not_paid'].indexOf(this.order.crmStatus.code) !== -1 && this.order.isRefundable;
		},
		showPayBtn() {
			return ['paid_in_part', 'tour_confirmed', 'waiting_for_payment', 'awaiting_payment'].indexOf(this.order.crmStatus.code) !== -1 && !this.order.isRefundable;
		},
		showCancelBtn() {
			return ['awaiting_confirmation', 'tour_confirmed', 'waiting_for_payment', 'requires_confirmation', 'not_paid'].indexOf(this.order.crmStatus.code) !== -1;
		},
		showRefundBtn() {
			return ['paid_in_part', 'tour_paid'].indexOf(this.order.crmStatus.code) !== -1;
		},
		showDocumentBtn() {
			return ['tour_paid'].indexOf(this.order.crmStatus.code) !== -1;
		}
	},
	mounted() {
		this.getPaymentList();

		if(!this.order.isRefundable){
			this.theTime()
		}

		if (this.showDocumentBtn) {
			this.getDocumentList();
		}
	},
	methods: {
		getPaymentList() {
			cabinet.order.paymentList(this.order.crmOrderId).then((r) => {
				this.paymentList = r.data
			})
		},
		getDocumentList() {
			cabinet.order.documentList(this.order.crmOrderId).then((r) => {
				this.documents = r.data
			})
		},
		getInvoiceOrder() {
			this.loadingInvoice = true;
			cabinet.order.invoice(this.order.crmOrderId).then((r) => {
				this.invoiceDialog.data = r.data
				this.invoiceDialog.show = true;
			}).finally(() => this.loadingInvoice = false)
		},
		refundOrder() {
			this.dialog.title = this.$t("account.dialogs.refund.title");
			this.dialog.action = this.refundOrderHandler;
			this.dialog.show = true;
		},
		cancelOrder() {
			this.dialog.title = this.$t("account.dialogs.cancel.title");
			this.dialog.action = this.cancelOrderHandler;
			this.dialog.show = true;
		},

		refundOrderHandler() {
			this.dialog.show = false;
			this.loadingRefund = true;
			cabinet.order.refund(this.order.crmOrderId).then(() => {
				this.$emit('getData')
			}).finally(() => this.loadingCancel = false)
		},
		cancelOrderHandler() {
			this.dialog.show = false;
			this.loadingCancel = true;
			cabinet.order.cancel(this.order.crmOrderId).then(() => {
				this.$emit('getData')
			}).finally(() => this.loadingCancel = false)
		},
		theTime() {
			let diff = this.order.payTime;
			let x = setInterval(() => {
				let tminutes = Math.floor((diff % (60 * 60)) / (60));
				let tseconds = Math.floor((diff % (60)));

				this.timer.minutes = (tminutes < 10) ? '0' + tminutes : tminutes;
				this.timer.seconds = (tseconds < 10) ? '0' + tseconds : tseconds;

				diff = diff - 1;
				if (diff < 0) {
					clearInterval(x);
					this.timer.expired = true;
					this.$emit('getData')
				}
			}, 1000);
		}
	}

}
</script>

<style lang="scss">
@import "@/styles/global/variables";
@import "@/styles/global/mixins";

.a-crm-info {
	flex-shrink: 0;
	width: 100%;
	@include minw($grid-breakpoints-md) {
		position: sticky;
		position: -webkit-sticky;
		top: 25px;
		z-index: 5;
		width: 272px;
		margin-left: 45px;
	}
	@include maxw($grid-breakpoints-md - 1) {
		padding-top: 30px;
		padding-left: 40px;
	}
	@include maxw($grid-breakpoints-xs - 1) {
		padding-left: 0;
	}

	&-pay-info {
		font-size: 13px;
		font-weight: 400;
	}

	&-wrap {
		@include minw($grid-breakpoints-xs) {
			box-shadow: 0px 0px 10px rgba(86, 86, 86, 0.1);
			border-radius: $border-radius;
			overflow: hidden;
		}
	}

	&-total {
		padding: 14px 15px;
		background-color: rgba($green-persian, .2);
		@include minw($grid-breakpoints-xs) {
			padding: 18px 20px;
		}

		&-text {
			font-size: 13px;
			font-weight: 400;
		}

		&-price {
			font-size: 16px;
			font-weight: 700;
			@include minw($grid-breakpoints-xs) {
				font-size: 18px;
			}
		}
	}

	&-content {
		&-link {
			font-size: 12px;
			padding: 16px 20px;
			display: block;
			text-align: center;
			@include minw($grid-breakpoints-xs) {
				padding-top: 20px;
				padding-bottom: 20px;
			}
		}

		&-list {
			padding: 0 15px;
			@include minw($grid-breakpoints-xs) {
				padding: 0 20px 20px;
			}
		}

		&-item {
			margin-bottom: 10px;
		}

		&-title,
		&-price {
			font-size: 12px;
			font-weight: 400;
		}

		&-title {
			color: rgba($black, .5);
		}
	}

	&-status {
		padding: 14px 15px;
		background-color: $green-persian;
		@include minw($grid-breakpoints-xs) {
			padding: 18px 20px;
		}
		@include maxw($grid-breakpoints-md - 1) {
			margin-top: 15px;
		}

		&-title {
			text-align: center;
			font-size: 16px;
			font-weight: 700;
			color: $white;
			@include minw($grid-breakpoints-xs) {
				font-size: 18px;
			}
		}
	}

	&-timer {
		padding: 14px 15px;
		@include minw($grid-breakpoints-xs) {
			padding: 18px 20px;
		}
		@include maxw($grid-breakpoints-md - 1) {
			margin-top: 15px;
		}

		&-title {
			text-align: center;
			font-size: 16px;
			font-weight: 700;
			color: $red;
			@include minw($grid-breakpoints-xs) {
				font-size: 18px;
			}
		}
	}

	&-action-buttons {
		padding: 15px 20px;

		.app-btn--block {
			margin-top: 15px;
		}

		&-pay-process {
			margin-bottom: 15px;
			padding: 15px;
			border: 2px solid $green-persian;
			border-radius: 5px;

			&-title {
				font-weight: 600;
				font-size: 15px;
				margin-bottom: 5px;
			}

			&-pay {
				font-size: 13px;
				margin-bottom: 5px;
			}
		}
	}
}
</style>
