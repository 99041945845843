<template>
	<div class='a-crm-tourist'>
		<div class='a-crm-tourist-header'>
			<p class='a-crm-tourist-header-title'>{{ $t("crm.tourists.title") }}</p>
		</div>

		<div class="a-crm-tourist-card" v-for="tourist in tourists" :key="tourist.id">
			<div class="a-crm-tourist-card-field" v-for="field in fields" :key="field.key">
				<div class="a-crm-tourist-card-field-label">{{ field.label }}</div>
				<div class="a-crm-tourist-card-field-value" v-if="field.key === 'date_expiration' || field.key === 'birthday'">
					{{ tourist[field.key] ? $moment(tourist[field.key], "YYYY-MM-DD").format("YYYY MMMM DD") : '' }}
				</div>
				<div class="a-crm-tourist-card-field-value" v-else>
					{{ tourist[field.key] }}
				</div>
			</div>
		</div>

	</div>
</template>

<script>

export default {
	props: ['tourists'],
	data: () => ({
		details: false,
		order: null,
		initLoading: true,
	}),



	computed: {
		fields () {
			return [
				{label: this.$t("crm.tourists.name"), key: 'name'},
				{label: this.$t("crm.tourists.surname"), key: 'surname'},
				{label: this.$t("crm.tourists.birthday"), key: 'birthday'},
				{label: this.$t("crm.tourists.number"), key: 'number'},
				{label: this.$t("crm.tourists.date_expiration"), key: 'date_expiration'},
				{label: this.$t("crm.tourists.nationality"), key: 'nationality'},
			];
		}
	},
	mounted() {
	},
	methods: {}

}
</script>

<style lang="scss">
@import "@/styles/global/variables";
@import "@/styles/global/mixins";

.a-crm-tourist {
	margin-top: 40px;

	&-header {
		&-title {
			font-size: 16px;
			font-weight: 700;
			@include minw($grid-breakpoints-xs) {
				font-size: 20px;
			}
		}
	}

	&-card {
		background: #FFFFFF;
		box-shadow: 0px 0px 20px rgb(86 86 86 / 20%);
		border-radius: 10px;
		padding: 20px;
		margin-top: 15px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		&-field{
			width: 33.3%;
			flex: 0 0 33.3%;
			box-sizing: border-box;
			margin: 10px 0;

			&-label{
				font-size: 13px;
				font-weight: 400;
				line-height: 1.5;
				color: rgba(0, 0, 0, 0.5);
			}
			&-value{
				font-weight: 700;
			}

		}
	}

}
</style>
